import axios from "@/http/http";
import {Module, ActionTree} from "vuex";

const actions: ActionTree<any, any> = {
    fetchAdminCourseDetails({commit, dispatch}, data) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                commit("SET_LOADING", true);
                const response = await axios.get("/api/v1/assessments/course/report/", {params: data});

                commit("setCourse", response.data);
                commit("SET_LOADING", false);
                resolve(response.data);
            } catch (err: any) {
                commit("SET_LOADING", false);
                try {
                    reject(err.response.data.message);
                } catch (e) {
                    reject(err.message);
                }
            }
        });
    },
};
const coursesStore: Module<any, any> = {
    state: () => ({
        adminCourse: null,
    }),
    mutations: {
        setCourse(state, adminCourse) {
            state.adminCourse = adminCourse;
        },
    },
    actions,
    getters: {
        adminCourse(state) {
            return state.adminCourse;
        }
    },
};

export default coursesStore;
